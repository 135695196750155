<template>
  <div id="AdminAddDietaryNeedsPerKg" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4 ml-auto">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="saveDietaryNeed()">
          Save
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ dietaryNeed.name }}<br>Dietary Recommendations (per kg body weight per day)<br><span class="text-base">with % Ranges for Daily Need vs Recipe Values</span></div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <TextInput
            label="Name"
            placeholder="Name of Recommendation"
            v-model:input="dietaryNeed.name"
            inputType="text"
            @keyup.enter="saveDietaryNeed()"
        />
      </div>
    </div>

    <div v-if="false" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Species
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="specieId"
                @change="getLifePhases()"
        >
          <option v-for="specie in species" :key="specie.id" :value="specie.id">{{ specie.name }}</option>
        </select>
      </div>
      <div class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Life Phase
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="dietaryNeed.life_phase_id"
        >
          <option v-for="lifePhase in lifePhases" :key="lifePhase.id" :value="lifePhase.id">{{ lifePhase.name }}</option>
        </select>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Species</label>
        <div class="flex flex-wrap">
          <label v-for="specie in species" :key="specie.id" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
            <input type="checkbox" value="" class="sr-only peer" v-model="specie.selected">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ specie.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Minimum Age"
            placeholder="0"
            v-model:input="dietaryNeed.age_gte"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Age Less Than"
            placeholder="1"
            v-model:input="dietaryNeed.age_lt"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
          Age Units
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="dietaryNeed.age_units"
        >
          <option v-for="ageUnit in ageUnits" :key="ageUnit" :value="ageUnit">{{ ageUnit }}</option>
        </select>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Minimum Weight"
            placeholder="0"
            v-model:input="dietaryNeed.weight_gte"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Weight Less Than"
            placeholder="10"
            v-model:input="dietaryNeed.weight_lt"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
          Weight Units
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="dietaryNeed.weight_units"
        >
          <option v-for="weightUnit in weightUnits" :key="weightUnit" :value="weightUnit">{{ weightUnit }}</option>
        </select>
      </div>
    </div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Activity Levels</label>
        <div class="flex flex-wrap">
          <label v-for="activityLevel in activityLevels" :key="activityLevel.id" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
            <input type="checkbox" value="" class="sr-only peer" v-model="activityLevel.selected">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ activityLevel.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="dietaryNeed.nutrition_breakdown.nutrients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Unit
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too Low Max %
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too High Min %
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Serving Size
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.nutrition_breakdown.total_qty" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        g
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 1-10 lbs)
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.energy_1to10lbs_kcal" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 11-33 lbs)
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.energy_11to33lbs_kcal" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 34-49 lbs)
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.energy_34to49lbs_kcal" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 50-69 lbs)
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.energy_50to69lbs_kcal" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Energy (adult 70+ lbs)
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.energy_70pluslbs_kcal" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        kcal
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr v-for="(nutrient, index) in dietaryNeed.nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="nutrient.amount.qty">
                      </td>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.unit_type }}
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-16" v-model="nutrient.amount.low_max">
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-16" v-model="nutrient.amount.high_min">
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  // import { useRoute } from 'vue-router'
  import { useRouter } from 'vue-router'
  import ActivityLevelService from '@/services/activityLevel.service'
  import DietaryNeedService from '@/services/dietaryNeed.service.js'
  import SpecieService from '@/services/specie.service'
  import LifePhaseService from '@/services/lifePhase.service'
  import TextInput from '../../components/global/TextInput.vue'
  import { useToast } from "vue-toastification"
  import Swal from 'sweetalert2'

  // const route = useRoute()
  const router = useRouter()
  const toast = useToast()

  let dietaryNeed = ref({name: '...', life_phase_id: '', nutrition_breakdown: {nutrients: []}})
  let errors = ref([])
  let loading = ref(true)
  let activityLevels = ref([])
  let species = ref([])
  let specieId = ref(null)
  let lifePhases = ref([])
  let ageUnits = ref(['years'])
  let weightUnits = ref(['kg'])

  onMounted(async () => {
      getFirstDietaryNeed()
      getActivityLevels()
      getSpecies()
  })

  const getFirstDietaryNeed = async () => {
    try {
      loading.value = true
      let res = await DietaryNeedService.getBlankDietaryNeed()
      dietaryNeed.value = res.data.dietary_per_kg_need
      dietaryNeed.value.age_units = 'years'
      dietaryNeed.value.weight_units = 'kg'
      // resetDietaryNeedToZero()
      loading.value = false
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
      loading.value = false
    }
  }

  const getActivityLevels = async () => {
    try {
      let res = await ActivityLevelService.listActivityLevels()
      activityLevels.value = res.data.activity_levels
    } catch (err) {
      console.log(err)
    }
  }

  const getSpecies = async () => {
    try {
      let res = await SpecieService.listSpecies()
      species.value = res.data.species
    } catch (err) {
      console.log(err)
    }
  }

  const getLifePhases = async () => {
    try {
      let res = await LifePhaseService.listLifePhasesForSpecie(specieId.value)
      lifePhases.value = res.data.life_phases
    } catch (err) {
      console.log(err)
    }
  }

  // const resetDietaryNeedToZero = async () => {
  //   dietaryNeed.value.name = ''
  //   dietaryNeed.value.nutrition_breakdown.total_qty = 0
  //   dietaryNeed.value.energy_puppy_kcal = 0
  //   dietaryNeed.value.energy_1to10lbs_kcal = 0
  //   dietaryNeed.value.energy_11to33lbs_kcal = 0
  //   dietaryNeed.value.energy_34to49lbs_kcal = 0
  //   dietaryNeed.value.energy_50to69lbs_kcal = 0
  //   dietaryNeed.value.energy_70pluslbs_kcal = 0
  //   for (let i = 0; i < dietaryNeed.value.nutrition_breakdown.nutrients.length; i++) {
  //     dietaryNeed.value.nutrition_breakdown.nutrients[i].amount.qty = 0
  //   }
  // }

  const saveDietaryNeed = async () => {

    let specieIds = species.value.filter(specie => specie.selected).map(specie => specie.id)
    if (specieIds.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least 1 Species',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }

    let activityLevelIds = activityLevels.value.filter(actitivyLevel => actitivyLevel.selected).map(actitivyLevel => actitivyLevel.id)
    if (activityLevelIds.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least 1 Activity Level',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }

    let data = new FormData();
    data.append('dietary_per_kg_need', JSON.stringify(dietaryNeed.value))
    data.append('specie_ids', JSON.stringify(specieIds))
    data.append('activity_level_ids', JSON.stringify(activityLevelIds))

    try {
      loading.value = true
      let res = await DietaryNeedService.createDietaryNeed(data)
      let newDt = res.data.dietary_per_kg_need
      loading.value = false
      toast.success(dietaryNeed.value.name + " created.")
      router.push({ name: 'EditDietaryNeedsPerKg', params: { id: newDt.id } })
    } catch (err) {
      errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      loading.value = false
    }
  }
</script>
