<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="text-gray-900 text-3xl text-center">New Recipe</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Recipe Name"
            placeholder="Doggie Meatloaf"
            v-model:input="recipeName"
            inputType="text"
            :error="errors.name ? errors.name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Max Batch Size is 2898 grams"
            placeholder="max batch size for ChefPaw model CP3 is 2898 grams"
            v-model:input="qty"
            inputType="text"
            :error="errors.total_qty ? errors.total_qty[0] : ''"
        />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <SubmitFormButton 
            btnText="Create Recipe"
            @click="createRecipe"
        />
      </div>
    </div>

  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service'
  import TextInput from '@/components/global/TextInput.vue'
  import SubmitFormButton from '@/components/global/SubmitFormButton.vue'

  const router = useRouter()

  let recipeName = ref(null)
  let qty = ref(null)
  let errors = ref([])

  const createRecipe = async () => {
    errors.value = []

    let data = new FormData();
    data.append('name', recipeName.value || '')
    data.append('total_qty', qty.value || '')
    data.append('total_unit_type', 'g')

    try {
      let res = await RecipeService.createRecipe(data)
      router.push({ name: 'EditRecipe', params: { id: res.data.recipe.id } })
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }
</script>
