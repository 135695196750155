<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goHome()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ getPageNameBaseOnType(type) }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="flex justify-start">
                          <a href="javascript:void(0)" @click="goToMakeBatch(recipe)" class="text-lg">{{ recipe.name }}</a>
                        </div>

                      </th>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="container max-w-4xl mx-auto text-xs text-gray-700 mt-10">
        <div class="text-center"><strong>Disclaimer for Suggested ChefPaw Recipes:</strong></div>
        <p>
          The suggested Chefpaw recipes are general recipes based on commonly available ingredients from grocery stores in  the USA. All pet food recipes require some nutritional supplementation in order to be considered balanced to AAFCO or NRC standards. Chefpaw’s dietary supplement is included with Chefpaw suggested recipes in order to supplement only the most common nutritional deficiencies. To add other brands of supplements, simply add the supplement and its nutritional details as a new ingredient and customize recipes as needed. These suggested recipes have not been evaluated by the AAFCO, NRC or USDA, and Innovet Pet Products has no affiliation with those organizations. Innovet Pet Products cannot guarantee that Chefpaw’s suggested recipes will be adequate for the specific needs of your particular pet. Custom recipes based off of your pets unique medical history can only be obtained by a licensed veterinarian.
        </p>
    </div>

  </div>
</template>

<script setup>

  import { onMounted, ref, toRefs } from 'vue'
  import { useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service.js'
  import PetService from '../../services/pet.service.js'
  import { defineProps } from 'vue'
  import { initFlowbite } from 'flowbite'

  const router = useRouter()
  const GetRecipeType = {
    MY_RECIPES : 'my-recipes',
    SUGGESTED_RECIPES : 'suggested-recipes',
    INSPIRED_RECIPES : 'inspired-recipes',
    SAVED_RECIPES : 'saved-recipes',
  }

  const props = defineProps({
    type: String,
  })

  const { type } = toRefs(props)

  let searching = ref(false);
  let recipes = ref([])
  let testRecipes = ref([])
  let pets = ref([])
  let errors = ref([])

  onMounted(async () => {
    await Promise.all([getRecipes(), getPets()])
    initFlowbite()
  })

  const getRecipesBaseOnType = (type) => {
    switch (type) {
      case GetRecipeType.INSPIRED_RECIPES:
        return RecipeService.listInspiredRecipes();
      case GetRecipeType.MY_RECIPES:
      case GetRecipeType.SAVED_RECIPES:
        return RecipeService.listMyRecipes();
      case GetRecipeType.SUGGESTED_RECIPES:
        return RecipeService.listSuggestedRecipes();
      default:
        return RecipeService.listRecipes();
    }
  }

  const getPageNameBaseOnType = (type) => {
    switch (type) {
      case GetRecipeType.INSPIRED_RECIPES:
        return 'Inspired Recipes';
      case GetRecipeType.MY_RECIPES:
        return 'My Recipes';
      case GetRecipeType.SUGGESTED_RECIPES:
        return 'Suggested Recipes';
      case GetRecipeType.SAVED_RECIPES:
        return 'Your Saved Recipes'
      default:
        return 'Which recipe do you want to prepare?';
    }
  }

  const getRecipes = async () => {
    searching.value = true
    try {
      let res = await getRecipesBaseOnType(type.value);
      recipes.value = res.data.recipes
      testRecipes.value = [res.data.recipes[0]]
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const getPets = async () => {
    if (type.value) return
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const goToMakeBatch = (recipe) => {
    router.push({ name: 'RecipesBatchSummary', params: { id: recipe.id } })
  }

  const goHome = () => {
    router.replace({ name: 'home' })
  }
</script>
