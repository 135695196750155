<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-10 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>
    
    <div class="text-gray-900 text-3xl text-center">{{ specie.name }} Recipes Home</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goSpecieRecipesSuggested()">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-thumbs-up w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Suggested Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes that ChefPaw has rigourously created utilizing industry expertise.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goSpecieRecipesCommunity()">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-people-arrows w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Community Inspired Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes created by other ChefPaw users.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goSpecieMyRecipes()">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-fingerprint w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">My Customized Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Recipes you created from scratch or by duplicating a Suggested Recipe.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import UserService from '@/services/user.service.js'
  import SpecieService from '@/services/specie.service'
  import { useUserStore } from '@/store/user-store.js'

  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  
  const specie = ref({name: '...'})
  const errors = ref([])
  const vetPage = ref('VeterinarianFormulatedRecipes')

  onMounted(async () => {
      await init()
  })

  const init = async () => {
    try {
      await UserService.getMe()
    } catch {
      await userStore.clearUser()
      router.push({ name: 'login' })
    }
    getSpecie()
    setVetFormulatedPage()
  }

  const getSpecie = async () => {
    try {
      let res = await SpecieService.getSpecie(route.params.slug)
      specie.value = res.data.specie
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const setVetFormulatedPage = async () => {
    try {
      let res = await UserService.getRecipesSharedWithMe();
      if (res.data.recipes.length > 0) vetPage.value = 'ListRecipesSharedWithMe'
      else vetPage.value = 'VeterinarianFormulatedRecipes'
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const goSpecieRecipesSuggested = () => {
    router.push({ name: 'SpecieRecipesCategories', params: { slug: specie.value.slug } })
  }
  
  const goSpecieRecipesCommunity = () => {
    router.push({ name: 'ListSpecieInspiredRecipes', params: { slug: specie.value.slug } })
  }

  const goSpecieMyRecipes = () => {
    router.push({ name: 'ListSpecieMyRecipes' })
  }

  const goBack = () => {
    router.go(-1)
  }

</script>
