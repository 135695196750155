<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Veterinarian Customized Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full text-center">
        For pets that have medical conditions or are pregnant or nursing, ChefPaw recommends the following professionals:
      </div>
      <div class="w-full text-center mt-4">
        Dr. Laura Gaylord, DVM<br>
        DACVIM (Nutrition)<br>
        Whole Pet Provisions<br>
        Board Certified Veterinary Nutritionist<br>
        <a href="https://www.wholepetprovisions.com/" target="_blank">www.wholepetprovisions.com</a>
        Email: <a href="mailto:info@wholepetprovisions.com">info@wholepetprovisions.com</a>
      </div>
    </div>
  </div>
</template>

<script setup>

  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const router = useRouter()

  onMounted(async () => {
    // nothing
  })

  const goBack = () => {
    router.go(-1)
  }
  
</script>
