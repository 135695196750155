<template>
  <div id="EditProfile" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="text-gray-900 text-3xl text-center">Add Pet</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <CropperModal
      v-if="showModal"
      :minAspectRatioProp="{width: 6, height: 8}"
      :maxAspectRatioProp="{width: 6, height: 8}"
      @croppedImageData="setCroppedImageData"
      @showModal="showModal = false"
    />

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Name"
            placeholder="Rover"
            v-model:input="name"
            inputType="text"
            :error="errors.name ? errors.name[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Weight (lbs)"
            placeholder="10"
            v-model:input="weightLbs"
            inputType="text"
            :error="errors.weight_kg ? errors.weight_kg[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Species
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="selSpeciesId"
                :disabled="!userStore.isAdmin"
        >
          <option v-for="specie in species" :key="specie.id" :selected="specie.id == selSpeciesId" :value="specie.id">{{ specie.name }}</option>
        </select>
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Breed"
            placeholder="Basenji"
            v-model:input="breedName"
            inputType="text"
            :error="errors.breed_name ? errors.breed_name[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Sex"
            placeholder="Female"
            v-model:input="sex"
            inputType="text"
            :error="errors.sex ? errors.sex[0] : ''"
        />
      </div>
      <div class="w-full md:w-1/2 px-3">
        <TextInput 
            label="Birthday"
            placeholder="36"
            v-model:input="birthday"
            inputType="date"
            :min="earliestBirthday" :max="latestBirthday"
            :error="errors.birthday ? errors.birthday[0] : ''"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Activity Level
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="selActivityLevelId"
        >
          <option v-for="level in activityLevels" :key="level.id" :selected="level.id == selActivityLevelId" :value="level.id">{{ level.name }}</option>
        </select>
      </div>
      <div v-if="dietaryNeeds.length > 1" class="w-full md:w-1/2 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
            Dietary Need
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="selDietaryNeedId"
                @change="selDietaryNeedChanged()"
        >
          <option v-for="dneed in dietaryNeeds" :key="dneed.id" :selected="dneed.id == selDietaryNeedId" :value="dneed.id">{{ dneed.name }}</option>
        </select>
      </div>
    </div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <DisplayCropperButton 
              label="Profile Photo"
              btnText="Update Profile Photo"
              @showModal="showModal = true"
          />
          <CroppedImage 
              :image="image"
          />
      </div>
    </div>

    <div class="flex flex-wrap mt-8 mb-6">
      <div class="w-full px-3">
        <button class="
          float-right
          mt-2
          inline-block
          bg-innoorange
          hover:bg-innoorange-hover
          font-bold
          text-white
          no-underline
          px-4
          py-3
        "
          type="submit"
          @click="submit" :disabled="isLoading">
          <svg v-if="isLoading" class="w-5 h-5 animate-spin ml-auto mr-auto" fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  fill="currentColor"></path>
          </svg>
          <span v-if="!isLoading">Save</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import Swal from 'sweetalert2'
  import TextInput from '@/components/global/TextInput.vue'
  import PetService from '@/services/pet.service'
  import ActivityLevelService from '@/services/activityLevel.service'
  import SpecieService from '@/services/specie.service'
  import DisplayCropperButton from '@/components/global/DisplayCropperButton.vue'
  import CropperModal from '@/components/global/CropperModal.vue'
  import CroppedImage from '@/components/global/CroppedImage.vue'
  import { useToast } from "vue-toastification"
  import { useUserStore } from '@/store/user-store.js'
  import userService from '@/services/user.service'

  const router = useRouter()
  const toast = useToast()
  const userStore = useUserStore()

  let birthday = ref(new Date().toISOString().slice(0, 10))
  let earliestBirthday = ref(new Date().toISOString().slice(0, 10))
  let latestBirthday = ref(new Date().toISOString().slice(0, 10))
  let name = ref(null)
  let weightLbs = ref(null)
  let breedName = ref(null)
  let sex = ref(null)
  let errors = ref([])
  let showModal = ref(false)
  let image = ref(null)
  let imageData = null
  let isLoading = ref(false)
  let selSpeciesId = ref('')
  let activityLevels = ref([])
  let selActivityLevelId = ref(null)
  let species = ref([])
  let dietaryNeeds = ref([{id: null, name: 'Default'}])
  let selDietaryNeedId = ref(null)
  let overrideDietaryNeed = ref(false)

  onMounted(() => {
    confirmAccessThenInit()
  })

  const init = async () => {
    birthday.value = yyyyMmDdYearsFromToday(-1)
    earliestBirthday.value = yyyyMmDdYearsFromToday(-30)
    latestBirthday.value = new Date().toISOString().slice(0, 10)

    await getSpecies()
    for (let i = 0; i < species.value.length; i++) {
      if (species.value[i].slug == 'dog') selSpeciesId.value = species.value[i].id
    }

    await getActivityLevels()
    for (let i = 0; i < activityLevels.value.length; i++) {
      if (activityLevels.value[i].name == 'Average') selActivityLevelId.value = activityLevels.value[i].id
    }

    getDneedsSharedWithMe()
  }

  const confirmAccessThenInit = async () => {
    try {
      let res = await PetService.listPets()
      if (userStore.isFreeUser && res.data.pets.length >= 1) {
        Swal.fire({
          title: 'Premium Feature',
          text: 'Sorry, free accounts are limited to 1 pet. Please make any desired changes to your existing pet.',
          icon: 'info',
          confirmButtonColor: '#F28705'
        }).then(() => {
          router.go(-1)
        })
      } else {
        init()
      }
    } catch (err) {
      // error
    }
  }

  const setCroppedImageData = (data) => {
    imageData = data
    image.value = data.imageUrl
  }

  const getActivityLevels = async () => {
    try {
      let res = await ActivityLevelService.listActivityLevels()
      activityLevels.value = res.data.activity_levels
    } catch (err) {
      console.log(err)
    }
  }

  const getSpecies = async () => {
    try {
      let res = await SpecieService.listSpecies()
      species.value = res.data.species
    } catch (err) {
      console.log(err)
    }
  }

  const getDneedsSharedWithMe = async () => {
    dietaryNeeds.value = [{id: null, name: 'Default'}]
    try {
      let res = await userService.getDietaryNeedsSharedWithMe()
      let dneeds = res.data.dietary_per_kg_needs
      for (let i = 0; i < dneeds.length; i++) {
        dietaryNeeds.value.push(dneeds[i])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const yyyyMmDdYearsFromToday = (years) => {
    let today = new Date()
    let xYearsFromToday = today.setFullYear(today.getFullYear() + years)
    return dateToYyyyMmDd(new Date(xYearsFromToday))
  }

  const dateToYyyyMmDd = (date) => {
    return date.toISOString().slice(0, 10)
  }

  const submit = async () => {
      isLoading.value = true  
      errors.value = []

      let data = new FormData();
      data.append('name', name.value || '')
      data.append('weight_kg', lbsToKg(weightLbs.value) || '')
      data.append('specie_id', selSpeciesId.value || '')
      data.append('breed_name', breedName.value || '')
      data.append('sex', sex.value || '')
      data.append('birthday', birthday.value || '')
      data.append('activity_level_id', selActivityLevelId.value || '')
      data.append('override_dietary_per_kg_need', overrideDietaryNeed.value || '')
      data.append('dietary_per_kg_need_id', selDietaryNeedId.value || '')

      if (imageData) {
        data.append('image', imageData.file || '')
        data.append('height', imageData.height || '')
        data.append('width', imageData.width || '')
        data.append('left', imageData.left || '')
        data.append('top', imageData.top || '')
      }
      
      try {
        await PetService.addPet(data)
        isLoading.value = false
        router.push('/account/pets')
      } catch (err) {
        isLoading.value = false
        toast.error(err.response.data.message)
        errors.value = err.response.data.errors || []
      }
  }

  const lbsToKg = (lbs) => {
    return lbs * 0.45359237
  }

  const selDietaryNeedChanged = () => {
    if (selDietaryNeedId.value == null) {
      overrideDietaryNeed.value = false
    } else {
      overrideDietaryNeed.value = true
    }
  }

  // const updateUser = async () => {
  //   errors.value = []

  //   let data = new FormData();
  //   // data.append('first_name', firstName.value || '')
  //   // data.append('last_name', lastName.value || '')

  //   // if (imageData) {
  //   //   data.append('image', imageData.file || '')
  //   //   data.append('height', imageData.heigh || '')
  //   //   data.append('width', imageData.width || '')
  //   //   data.append('left', imageData.left || '')
  //   //   data.append('top', imageData.top || '')
  //   // }

  //   try {
  //     await axios.post('users/me?_method=PUT', data)

  //     await userStore.fetchUser()

  //     router.push('/account/profile')
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }
</script>
