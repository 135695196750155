<template>
  <div id="listRecipeStepTemplateGroups" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goAdminHome()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Recipe Step Template Groups</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="groups.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-[200px]">
              <tbody>
                  <tr v-for="(group, index) in groups" :key="group.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="flex justify-start">
                          <a href="javascript:void(0)" @click="goToEditGroup(group)" class="text-lg">{{ group.name }}</a>
                        </div>
                      </th>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>
  </div>
</template>

<script setup>

  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import RecipeStepTemplateGroupService from '../../services/recipeStepTemplateGroup.service.js'

  const router = useRouter()

  let errors = ref([])
  let groups = ref([])

  onMounted(async () => {
    getGroups()
  })

  const getGroups = async () => {
    try {
      let res = await RecipeStepTemplateGroupService.listRecipeStepTemplateGroups();
      groups.value = res.data.recipe_step_template_groups
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const goToEditGroup = (group) => {
    router.push({ name: 'EditRecipeStepTemplateGroup', params: { id: group.id } })
  }

  const goAdminHome = () => {
    router.replace({ name: 'AdminHome' })
  }
</script>
