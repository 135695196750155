<template>
  <div id="AdminEditDietaryNeedsPerKg" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4 ml-auto">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="saveDietaryNeed()">
          Save
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">{{ dietaryNeed.name }}<br>Dietary Recommendations (per kg body weight per day)<br><span class="text-base">with % Ranges for Daily Need vs Recipe Values</span></div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    
    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <TextInput
            label="Name"
            placeholder="Highly Active"
            v-model:input="dietaryNeed.name"
            inputType="text"
            @keyup.enter="saveDietaryNeed()"
        />
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Species</label>
        <div class="flex flex-wrap">
          <label v-for="specie in species" :key="specie.id" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
            <input type="checkbox" value="" class="sr-only peer" v-model="specie.selected">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ specie.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Minimum Age"
            placeholder="0"
            v-model:input="dietaryNeed.age_gte"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Age Less Than"
            placeholder="1"
            v-model:input="dietaryNeed.age_lt"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
          Age Units
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="dietaryNeed.age_units"
        >
          <option v-for="ageUnit in ageUnits" :key="ageUnit" :value="ageUnit">{{ ageUnit }}</option>
        </select>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Minimum Weight"
            placeholder="0"
            v-model:input="dietaryNeed.weight_gte"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <TextInput
            label="Weight Less Than"
            placeholder="10"
            v-model:input="dietaryNeed.weight_lt"
            inputType="text"
        />
      </div>
      <div class="w-full md:w-1/3 px-3">
        <label 
          class="
              block 
              uppercase 
              tracking-wide 
              text-xs 
              font-bold 
              mb-2
              text-gray-900
          "
        >
          Weight Units
        </label>
        <select class="
                appearance-none
                block
                w-full
                bg-white
                text-gray-700
                border
                border-gray-400
                rounded
                py-3
                px-4
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
                "
                v-model="dietaryNeed.weight_units"
        >
          <option v-for="weightUnit in weightUnits" :key="weightUnit" :value="weightUnit">{{ weightUnit }}</option>
        </select>
      </div>
    </div>

    <div v-if="!loading" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full px-3">
        <label class="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2">Activity Levels</label>
        <div class="flex flex-wrap">
          <label v-for="activityLevel in activityLevels" :key="activityLevel.id" class="relative inline-flex items-center mr-5 cursor-pointer mr-8 my-4">
            <input type="checkbox" value="" class="sr-only peer" v-model="activityLevel.selected">
            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
            <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ activityLevel.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div v-if="userOwnsDietaryNeed" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full text-xl">
        <TextInput 
            label="Share these Dietary Recommendations with specific ChefPaw Users. Add 1 email at a time and press enter to send Dietary Recommendations after each email is added"
            placeholder="add 1 email at a time and press enter to send Dietary Recommendations after each email is added"
            v-model:input="shareWithEmail"
            inputType="text"
            @keyup.enter="shareWithUser()"
        />
      </div>
    </div>

    <div v-if="userOwnsDietaryNeed" class="flex flex-wrap mt-4 mb-6">
      <div class="w-full">
        <div class="flex flex-wrap">
          <span v-for="email in sharedWithEmails" :key="email" class="inline-flex items-center mx-1 px-2 py-1 me-2 text-sm font-medium text-gray-900 bg-innoorange rounded dark:bg-innoorange dark:text-gray-900">
            {{ email }}
            <button @click="unshareWithUser(email)" type="button" class="inline-flex items-center p-1 ms-2 text-sm text-gray-900 bg-transparent rounded-sm hover:bg-innoorange-hover hover:text-gray-900 dark:hover:bg-innoorange-hover dark:hover:text-gray-300" data-dismiss-target="#badge-dismiss-default" aria-label="Unshare">
              <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Unshare With {{ email }}</span>
            </button>
          </span>
        </div>
      </div>
    </div>

    <div v-if="dietaryNeed.nutrition_breakdown.nutrients.length > 0" class="pt-5 pb-5">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Nutrient
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Unit
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too Low Max %
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Too High Min %
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Serving Size
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="dietaryNeed.nutrition_breakdown.total_qty" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        g
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                      <td class="px-6 py-4">
                        n/a
                      </td>
                  </tr>
                  <tr v-for="(nutrient, index) in dietaryNeed.nutrition_breakdown.nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-6 py-4">
                        <input type="text" v-model="nutrient.amount.qty" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        {{ nutrient.amount.unit_type }}
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-16" v-model="nutrient.amount.low_max" @keyup.enter="saveDietaryNeed">
                      </td>
                      <td class="px-6 py-4">
                        <input type="text" class="w-16" v-model="nutrient.amount.high_min" @keyup.enter="saveDietaryNeed">
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import DietaryNeedService from '@/services/dietaryNeed.service.js'
  import ActivityLevelService from '@/services/activityLevel.service'
  import LifePhaseService from '@/services/lifePhase.service'
  import TextInput from '../../components/global/TextInput.vue'
  import { useToast } from "vue-toastification"
  import Swal from 'sweetalert2'
  import UserService from '../../services/user.service'
  import { useUserStore } from '../../store/user-store'
  import SpecieService from '@/services/specie.service'

  const route = useRoute()
  const toast = useToast()
  const userStore = useUserStore()

  let dietaryNeed = ref({name: '...', nutrition_breakdown: {nutrients: []}})
  let errors = ref([])
  let loading = ref(true)
  let activityLevels = ref([])
  let lifePhases = ref([])
  let shareWithEmail = ref('')
  let sharedWithEmails = ref([])
  let species = ref([])
  let ageUnits = ref(['years'])
  let weightUnits = ref(['kg'])

  onMounted(async () => {
    await getDietaryNeed()
    // might be a more efficient way to do this in the future,
    // but for security we're making a separate call through
    // user controller
    if (userOwnsDietaryNeed.value) getSharedDietaryNeed()
    getLifePhases()
    getActivityLevels()
    getSpecies()
  })

  const getDietaryNeed = async () => {
    try {
      loading.value = true
      let res = await DietaryNeedService.getDietaryNeedWithAcitivityLevelsAndLifePhase(route.params.id)
      dietaryNeed.value = res.data.dietary_per_kg_need
      loading.value = false
      console.log(res.data)
    } catch (err) {
      errors.value = err.response.data.errors
      loading.value = false
    }
  }

  const getSharedDietaryNeed = async () => {
    try {
      let res = await UserService.getSharedDietaryNeed(route.params.id)
      updateSharedWithEmails(res.data.dietary_per_kg_need)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getActivityLevels = async () => {
    try {
      let res = await ActivityLevelService.listActivityLevels()
      activityLevels.value = res.data.activity_levels
      // Add a 'selected' property to each item in activityLevels
      activityLevels.value = activityLevels.value.map(activityLevel => ({
          ...activityLevel,
          selected: dietaryNeed.value.activity_levels.some(selActivityLevel => selActivityLevel.id === activityLevel.id)
      }));
    } catch (err) {
      console.log(err)
    }
  }

  const getLifePhases = async () => {
    try {
      let res = await LifePhaseService.listLifePhasesForSpecie(dietaryNeed.value.life_phase.specie_id)
      lifePhases.value = res.data.life_phases
    } catch (err) {
      console.log(err)
    }
  }

  const getSpecies = async () => {
    try {
      let res = await SpecieService.listSpecies()
      species.value = res.data.species
      species.value = species.value.map(specie => ({
          ...specie,
          selected: dietaryNeed.value.species.some(selSpecie => selSpecie.id === specie.id)
      }));
    } catch (err) {
      console.log(err)
    }
  }

  const saveDietaryNeed = async () => {
    let specieIds = species.value.filter(specie => specie.selected).map(specie => specie.id)
    if (specieIds.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least 1 Species',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }

    let activityLevelIds = activityLevels.value.filter(actitivyLevel => actitivyLevel.selected).map(actitivyLevel => actitivyLevel.id)
    if (activityLevelIds.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least 1 Activity Level',
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      return
    }

    let data = new FormData();
    data.append('dietary_per_kg_need', JSON.stringify(dietaryNeed.value))
    data.append('specie_ids', JSON.stringify(specieIds))
    data.append('activity_level_ids', JSON.stringify(activityLevelIds))

    try {
      loading.value = true
      let res = await DietaryNeedService.updateDietaryNeed(dietaryNeed.value.id, data)
      dietaryNeed.value = res.data.dietary_per_kg_need
      loading.value = false
      toast.success(dietaryNeed.value.name + " saved.")
    } catch (err) {
      errors.value = err.response.data.errors
      Swal.fire({
        title: 'Error',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonColor: '#F28705'
      })
      loading.value = false
    }
  }

  const updateSharedWithEmails = (dneedWithSharedUsers) => {
    sharedWithEmails.value = []
    for (let i = 0; i < dneedWithSharedUsers.users_shared_with.length; i++) {
      sharedWithEmails.value.push(dneedWithSharedUsers.users_shared_with[i].email)
    }
  }

  const shareWithUser = async () => {
    let emails = [shareWithEmail.value]
    try {
      let res = await UserService.shareDietaryNeed(dietaryNeed.value.id, emails)
      toast.success("Shared with " + shareWithEmail.value)
      shareWithEmail.value = ''
      updateSharedWithEmails(res.data.dietary_per_kg_need)
    } catch (err) {
      toast.error(err.response.data.error)
    }
  }

  const unshareWithUser = async (email) => {
    let emails = [email]
    try {
      let res = await UserService.unshareDietaryNeed(dietaryNeed.value.id, emails)
      toast.success("Unshared with " + email)
      updateSharedWithEmails(res.data.dietary_per_kg_need)
    } catch (err) {
      toast.error(err.response.data.error)
    }
  }

  const userOwnsDietaryNeed = computed({
    get: () => dietaryNeed.value.user_id == userStore.id
  })
</script>
