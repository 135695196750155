<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-10 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>
    
    <div class="text-gray-900 text-3xl text-center">Suggested {{ specieName() }} Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="grid place-items-center">
      <div v-for="tag in tags" :key="tag.slug" class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="goSpecieRecipesCategory(tag)">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid w-5 h-5" :class="tag.icon_css_class"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">{{ tag.name.en }}</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">{{ tag.description }}</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import UserService from '@/services/user.service.js'
  import { useUserStore } from '@/store/user-store.js'
  import TagService from '@/services/tag.service'
  import { useToast } from 'vue-toastification'

  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  const toast = useToast()

  const tags = ref([])

  onMounted(async () => {
      await init()
  })

  const init = async () => {
    try {
      await UserService.getMe()
    } catch {
      await userStore.clearUser()
      router.push({ name: 'login' })
    }
    getSpecieRecipeTags()
  }

  const getSpecieRecipeTags = async () => {
    try {
      let res = await TagService.listSpecieRecipeTagsAndOmitTagsWithParent(route.params.slug)
      tags.value = res.data.tags
      console.log(res.data.tags)
    } catch (err) {
      toast.error('Failed to load ' + route.params.slug+ ' Recipe Tags')
    }
  }

  const goSpecieRecipesCategory = (tag) => {
    router.push({ name: 'SpecieRecipesCategory', params: {  slug: route.params.slug, tag: tag.slug.en } })
    
  }

  const specieName = () => {
    const capitalized =
      route.params.slug.charAt(0).toUpperCase()
      + route.params.slug.slice(1)
    return capitalized
  }

  // const goDogRecipesSpecialty = () => {
  //   router.push({ name: 'DogRecipesSpecialty' })
  // }

  const goBack = () => {
    router.go(-1)
  }

</script>
