<template>
  <div id="VeterinarianFormulatedRecipesOrder" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Veterinarian Customized Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="flex flex-wrap mt-4 mb-6">
      <div class="w-full text-left pr-4">
        <p>To order a custom recipe for a healthy pet that has no medical conditions, please fill out the following form, and click "Submit Request". Note: We cannot make custom recipes for pets with medical conditions. If your pet has a medical condition, please refer to the link on the previous page.</p>
        <p class="mt-4">Our team of experts will review your pet's profile and recipe request; when the custom recipe is ready we will email you an invoice. Once the invoice is paid we will put the custom recipe into the "Veterinarian Customized Recipes" section of your account. The fee for this service is $60 for the 1st recipe, or $100 for 2 recipes. Wait times range from 2-10 business days.</p>
      </div>
    </div>

    <form @submit.prevent="submitForm" class="mt-8">
      <div class="space-y-6">
        <!-- Pet Selection -->
        <div>
          <label for="pet" class="block font-semibold">Which pet will the recipe be for?</label>
          <select 
            id="pet" 
            v-model="formData.pet_id"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
            required
          >
            <option value="">Select a pet</option>
            <option v-for="pet in pets" :key="pet.id" :value="pet.id">
              {{ pet.name }}
            </option>
          </select>
        </div>

        <!-- Number of Recipes -->
        <div>
          <label for="recipeCount" class="block font-semibold">How many custom recipes would you like?</label>
          <select 
            id="recipe_count" 
            v-model="formData.recipe_count"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
            required
          >
            <option value="1">1 Recipe ($60)</option>
            <option value="2">2 Recipes ($100)</option>
          </select>
        </div>

        <!-- Body Condition -->
        <div>
          <label class="block font-semibold">Select your pet's body condition</label>
          <div class="mt-2 space-y-2">
            <div v-for="option in bodyConditionOptions" :key="option.value">
              <label class="inline-flex items-center">
                <input 
                  type="radio" 
                  :value="option.value" 
                  v-model="formData.body_condition"
                  class="form-radio text-innogreen"
                  required
                >
                <span class="ml-2">{{ option.label }}</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Spayed/Neutered -->
        <div>
          <label class="block font-semibold">Is this pet spayed or neutered?</label>
          <div class="mt-2 space-y-2">
            <div>
              <label class="inline-flex items-center">
                <input 
                  type="radio" 
                  value="yes" 
                  v-model="formData.is_fixed"
                  class="form-radio text-innogreen"
                  required
                >
                <span class="ml-2">Yes</span>
              </label>
            </div>
            <div>
              <label class="inline-flex items-center">
                <input 
                  type="radio" 
                  value="no" 
                  v-model="formData.is_fixed"
                  class="form-radio text-innogreen"
                  required
                >
                <span class="ml-2">No</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Avoid Ingredients -->
        <div>
          <label for="avoidIngredients" class="block font-semibold">Are there any ingredients that you would like to avoid?</label>
          <textarea 
            id="avoid_ingredients" 
            v-model="formData.avoid_ingredients"
            rows="3"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
          ></textarea>
        </div>

        <!-- Include Ingredients -->
        <div>
          <label for="includeIngredients" class="block font-semibold">Are there any ingredients that you would like included?</label>
          <textarea 
            id="include_ingredients" 
            v-model="formData.include_ingredients"
            rows="3"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
          ></textarea>
        </div>

        <!-- Goals -->
        <div>
          <label for="goals" class="block font-semibold">Do you have any specific goals for the recipe?</label>
          <textarea 
            id="goals" 
            v-model="formData.goals"
            rows="3"
            placeholder="Ie: highest quality, lowest cost, improve skin/coat"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
          ></textarea>
        </div>

        <!-- Allergies -->
        <div>
          <label for="allergies" class="block font-semibold">Any known food allergies?</label>
          <textarea 
            id="allergies" 
            v-model="formData.allergies"
            rows="3"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-innogreen focus:ring-innogreen"
          ></textarea>
        </div>

        <!-- Submit Button -->
        <div class="flex justify-end">
          <button
            type="submit"
            class="float-right
                text-white
                hover:text-white
                py-2
                px-4
                rounded
                w-full
                text-center
                text-lg mt-2
                bg-innoorange
                hover:bg-innoorange-hover"
          >
            Submit Request
          </button>
        </div>
      </div>
    </form>
    
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import PetService from '../../services/pet.service'
import VeterinarianFormulatedRecipeOrderService from '../../services/veterinarianFormulatedRecipeOrder.service'
import Swal from 'sweetalert2'

const router = useRouter()
const pets = ref([])
const formData = ref({
  pet_id: '',
  recipe_count: '1',
  body_condition: '',
  is_fixed: '',
  avoid_ingredients: '',
  include_ingredients: '',
  goals: '',
  allergies: ''
})

const bodyConditionOptions = [
  { value: 'very-underweight', label: 'Very Underweight' },
  { value: 'slightly-underweight', label: 'Slightly Underweight' },
  { value: 'just-right', label: 'Just Right' },
  { value: 'slightly-overweight', label: 'Slightly Overweight' },
  { value: 'very-overweight', label: 'Very Overweight' }
]

onMounted(async () => {
    await getPets()
})

const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      // error
    }
  }

const submitForm = async () => {
  try {
    await VeterinarianFormulatedRecipeOrderService.createOrder(formData.value)
    Swal.fire({
      title: 'Request Submitted',
      text: 'Your request has been submitted. Thank you!',
      icon: 'success',
      confirmButtonColor: '#F28705'
    })
    goBack()
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'There was an error submitting your request. Please try again or contact support@chefpaw.com.',
      icon: 'error',
      confirmButtonColor: '#F28705'
    })
  }
}

const goBack = () => {
  router.go(-1)
}
</script>
