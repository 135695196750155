<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goAdminHome()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Community Inspired Recipes</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="recipes.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              
                <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                    <tr>
                        <th scope="col" class="px-6 py-6">
                            Recipe Name
                        </th>
                        <th scope="col" class="px-6 py-6">
                            Admin Approved
                        </th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(recipe, index) in recipes" :key="recipe.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div class="flex justify-start">
                          <a href="javascript:void(0)" @click="goToShowRecipe(recipe)" class="text-lg">{{ recipe.name }}</a>
                        </div>

                      </th>
                      <td class="px-6 py-4">
                        <label class="relative inline-flex items-center mr-5 cursor-pointer">
                          <input type="checkbox" value="" class="sr-only peer" v-model="recipe.share_is_approved">
                          <div @click="toggleApproval(recipe)" class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-innogreen dark:peer-focus:ring-innogreen dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-innogreen"></div>
                          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ recipe.share_is_approved ? 'Shared' : 'Not Approved' }}</span>
                        </label>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="container max-w-4xl mx-auto text-xs text-gray-700 mt-4">
        <div class="text-center"><strong>Disclaimer for Suggested ChefPaw Recipes:</strong></div>
        <p>
          The suggested Chefpaw recipes are general recipes based on commonly available ingredients from grocery stores in  the USA. All pet food recipes require some nutritional supplementation in order to be considered balanced to AAFCO or NRC standards. Chefpaw’s dietary supplement is included with Chefpaw suggested recipes in order to supplement only the most common nutritional deficiencies. To add other brands of supplements, simply add the supplement and its nutritional details as a new ingredient and customize recipes as needed. These suggested recipes have not been evaluated by the AAFCO, NRC or USDA, and Innovet Pet Products has no affiliation with those organizations. Innovet Pet Products cannot guarantee that Chefpaw’s suggested recipes will be adequate for the specific needs of your particular pet. Custom recipes based off of your pets unique medical history can only be obtained by a licensed veterinarian.
        </p>
    </div>

  </div>
</template>

<script setup>

  import { onMounted, ref, toRefs } from 'vue'
  import { useRouter } from 'vue-router'
  import RecipeService from '../../services/recipe.service.js'
  import PetService from '../../services/pet.service.js'
  import { defineProps } from 'vue'
  import { initFlowbite } from 'flowbite'
  import { useToast } from "vue-toastification"

  const router = useRouter()
  const toast = useToast()

  const props = defineProps({
    type: String,
  })

  const { type } = toRefs(props)

  let searching = ref(false);
  let recipes = ref([])
  let testRecipes = ref([])
  let pets = ref([])
  let errors = ref([])

  onMounted(async () => {
    await Promise.all([getRecipes(), getPets()])
    initFlowbite()
  })

  const getRecipes = async () => {
    searching.value = true
    try {
      let res = await RecipeService.adminListAllSharedRecipes()
      recipes.value = res.data.recipes
      testRecipes.value = [res.data.recipes[0]]
      searching.value = false
    } catch (err) {
      errors.value = err.response.data.errors
      searching.value = false
    }
  }

  const toggleApproval = async (recipe) => {
    console.log('clicked')
    try {
      let res = await RecipeService.adminToggleSharedRecipeApproval(recipe.id)
      recipe = res.data.recipe
      toast.success(recipe.share_is_approved ? recipe.name + " approved." : recipe.name + " unapproved.")
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getPets = async () => {
    if (type.value) return
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const goToShowRecipe = (recipe) => {
    router.push({ name: 'ShowRecipe', params: { id: recipe.id } })
  }

  const goAdminHome = () => {
    router.replace({ name: 'AdminHome' })
  }
</script>
