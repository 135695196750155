<template>
  <div id="AdminHome" class="container max-w-4xl mx-auto pt-10 pb-20 px-6">

    <div class="text-gray-900 text-3xl text-center">Admin Home</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>
    
    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListRecipes')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-book-journal-whills w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">All Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">All recipes in the system except unshared user recipes.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('SharedRecipes')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-people-arrows w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Approve Community Inspired Recipes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Approve and unapprove Community Inspired Recipes.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListUsers')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-user-pen w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Manage Users</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Manage ChefPaw app users.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListIngredients')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-egg w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Ingredients</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">View and modify the ingredients that are available to create recipes from.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListDietaryNeedsPerKg')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-flask w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Dietary Recommendations</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">View and modify dietary needs/recommendations (per pet kg).</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('NutrientAafcoBounds')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-percent w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">AAFCO Nutrient Bounds</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Lower and upper bounds for AAFCO Dry Matter Basis &amp; Per 1000 kcal (DM) for adult dogs and puppies.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListNutrients')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-percent w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Default Deviation Ranges &amp; High/Low Notes</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Default values that determine the red, yellow, and green zones for % difference between daily needs and recipe values.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 w-11/12 md:w-2/3">
        <div class="flex rounded-lg h-full bg-white p-8 flex-col hover:bg-innocream cursor-pointer" @click="routerPushName('ListRecipeStepTemplateGroups')">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-innogreen text-white flex-shrink-0">
              <i class="fa-solid fa-shoe-prints w-5 h-5"></i>
            </div>
            <h2 class="text-gray-900 text-lg title-font font-medium">Recipe Step Template Groups</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base">Modify recipe step verbiage and content.</p>
            <a class="mt-3 text-innogreen inline-flex items-center">Go now
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import UserService from '@/services/user.service.js'
  import { useUserStore } from '@/store/user-store.js'

  const router = useRouter()
  const userStore = useUserStore()

  onMounted(async () => {
      await init()
  })

  const init = async () => {
    try {
      await UserService.getMe()
    } catch {
      await userStore.clearUser()
      router.push({ name: 'login' })
    }
  }

  const routerPushName = (routeName) => {
    router.push({ name: routeName })
  }
</script>
