<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="text-gray-900 text-3xl text-center">{{ mealPlan.name }} for {{ pet.name }}</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div class="text-gray-900 text-xl text-center">Based on {{ pet.name }} eating {{ petGramsPerDayRounded }} grams (approx. {{ petCupsPerDayText }}) of food per day from the recipes in the {{ mealPlan.name }} meal plan, the following overall nutritional profile is expected.</div>

    <div v-if="pet.daily_nutrition_breakdown != null" class="pt-5 pb-5">
      <div class="p-2 text-right">
        <label for="showRecipes" class="p-2">Show Recipes</label>
        <input type="checkbox" id="showRecipes" v-model="showRecipes" class="p-2">
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-[700px]">
          <table v-if="showRecipes" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-4 py-6">
                          Item
                      </th>
                      <th scope="col" class="px-4 py-6">
                        {{ pet.name }} Daily Need
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Meal Plan Provides
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Nutrient Level
                      </th>
                      <th scope="col" class="px-4 py-6" v-for="recipe in mealPlan.recipes" :key="recipeHeaderKey(recipe)">
                          {{ recipe.name }}
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Total Grams
                    </th>
                    <td scope="row" class="px-4 py-3">
                      {{ round(petGramsPerDay) }}
                    </td>
                    <td scope="row" class="px-4 py-3">
                      {{ round(petGramsPerDay) }}
                    </td>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                    <td class="px-4 py-4" v-for="(recipe, index) in mealPlan.recipes" :key="recipeTotalKey(recipe)">
                      {{ calcRecipeValue(index, recipe.nutrition_breakdown.total_qty) }}
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      % of Meal Plan
                    </th>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                    <td class="px-4 py-4" v-for="(recipe, index) in mealPlan.recipes" :key="recipePercentKey(recipe)">
                      {{ round(recipesPercentOfMealPlan[index]) }}%
                    </td>
                  </tr>
                  <tr v-for="(nutrient, index) in petNutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                          {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-4 py-4">
                        {{ round(nutrient.amount.qty) }}{{ nutrient.amount.unit_type }}
                      </td>
                      <td class="px-4 py-4">
                        {{ round(planNutrients[index].amount.qty) }}{{ planNutrients[index].amount.unit_type }}
                      </td>
                      <td class="px-4 py-4" :class="[rangeClass(index)]">
                        {{ rangeLabel(index) }}
                      </td>
                      <td class="px-4 py-4" v-for="(recipe, recipe_index) in mealPlan.recipes" :key="recipeBodyKey(recipe)">
                        {{ calcRecipeValue(recipe_index, recipe.nutrition_breakdown.nutrients[index].amount.qty) }}{{ recipe.nutrition_breakdown.nutrients[index].amount.unit_type }}
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Ca/P Ratio
                      </th>
                      <td class="px-4 py-4">
                        1:1 to 2:1
                      </td>
                      <td class="px-4 py-4">
                        {{ formattedMealPlanCaPRatio }}
                      </td>
                      <td class="px-4 py-4" :class="[caPRatioClass()]">
                        {{ caPRatioLabel() }}
                      </td>
                      <td class="px-4 py-4" v-for="recipe in mealPlan.recipes" :key="recipeCaPRatioKey(recipe)">
                        {{ formattedRecipeCaPRatio(recipe) }}
                      </td>
                  </tr>
              </tbody>
          </table>
          <table v-if="!showRecipes" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="sticky top-0 text-xs text-white uppercase bg-innogreen">
                  <tr>
                      <th scope="col" class="px-4 py-6">
                          Item
                      </th>
                      <th scope="col" class="px-4 py-6">
                        {{ pet.name }} Daily Need
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Meal Plan Provides
                      </th>
                      <th scope="col" class="px-4 py-6">
                          Nutrient Level
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Total Grams
                    </th>
                    <td scope="row" class="px-4 py-3">
                      {{ round(petGramsPerDay) }}
                    </td>
                    <td scope="row" class="px-4 py-3">
                      {{ round(petGramsPerDay) }}
                    </td>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <th scope="row" class="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      % of Meal Plan
                    </th>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                    <td scope="row" class="px-4 py-3">
                      100%
                    </td>
                    <td scope="row" class="px-4 py-3">
                      N/A
                    </td>
                  </tr>
                  <tr v-for="(nutrient, index) in petNutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="md:hidden px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Popper :content="nutrient.name" :disabled="nutrient.name.length < nutrientMaxChars">
                          {{ $filters.strLimit(nutrient.name, nutrientMaxChars) }}
                        </Popper>
                      </th>
                      <th scope="row" class="hidden md:table-cell px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-4 py-4">
                        {{ round(nutrient.amount.qty) }}{{ nutrient.amount.unit_type }}
                      </td>
                      <td class="px-4 py-4">
                        {{ round(planNutrients[index].amount.qty) }}{{ planNutrients[index].amount.unit_type }}
                      </td>
                      <td class="px-4 py-4" :class="[rangeClass(index)]">
                        {{ rangeLabel(index) }}
                      </td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <th scope="row" class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Ca/P Ratio
                      </th>
                      <td class="px-4 py-4">
                        1:1 to 2:1
                      </td>
                      <td class="px-4 py-4">
                        {{ formattedMealPlanCaPRatio }}
                      </td>
                      <td class="px-4 py-4" :class="[caPRatioClass()]">
                        {{ caPRatioLabel() }}
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>


  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import _ from 'lodash';
  import PetService from '../../services/pet.service.js'
  import MealPlanService from '../../services/mealPlan.service.js'
  import CaPRatioService from '../../services/caPRatio.service.js'
  import Popper from "vue3-popper"

  const route = useRoute()

  let pet = ref({name: '', daily_nutrition_breakdown: null})
  let petGramsPerDay = ref('')
  let mealPlan = ref({})
  let petNutrients = ref([])
  let planNutrients = ref([])
  let targetDeviations = ref([])
  let nutrientRanges = ref([]) // 1 for low, 2 for optimal, 3 for high
  let recipeMultipliers = ref([])
  let recipesPercentOfMealPlan = ref([])
  let recipeNutrients = ref([])
  let caPRatio = ref({low_max: 50, high_min: 150})
  let mealPlanCaAmount = ref(0)
  let mealPlanPAmount = ref(0)
  let showRecipes = ref(false)
  let nutrientMaxChars = ref(12)
  
  // let errors = ref([])

  onMounted(async () => {
      Promise.all([PetService.getPetWithDailyNutritionAndDietaryPerKgNeed(route.params.petId), MealPlanService.getMealPlanWithNutritionAndRecipeNutrition(route.params.mealPlanId), getCaPRatio()]).then((values) => {
        pet.value = values[0].data.pet
        mealPlan.value = values[1].data.meal_plan
        petNutrients.value = []
        planNutrients.value = []
        targetDeviations.value = []
        nutrientRanges.value = []
        recipeNutrients.value = []
        recipesPercentOfMealPlan.value = []
        petGramsPerDay.value = pet.value.daily_nutrition_breakdown.total_qty
        const plan_multiplier = petGramsPerDay.value / mealPlan.value.nutrition_breakdown.total_qty
        for (let i = 0; i < pet.value.daily_nutrition_breakdown.nutrients.length; i++) {
          let nutrient = pet.value.daily_nutrition_breakdown.nutrients[i]
          petNutrients.value.push(nutrient)
          if(!_.find(mealPlan.value.nutrition_breakdown.nutrients, {id: nutrient.id})) {
            let plan_nutrient = structuredClone(nutrient)
            plan_nutrient.amount.qty = 0
            planNutrients.value.push(plan_nutrient)
          }
        }
        for (let i = 0; i < mealPlan.value.nutrition_breakdown.nutrients.length; i++) {
          let nutrient = mealPlan.value.nutrition_breakdown.nutrients[i]
          if (nutrient.amount.qty > 0) nutrient.amount.qty = Math.round(nutrient.amount.qty * plan_multiplier * 1000) / 1000

          // maybe in the future we'll change how this works
          if (nutrient.name == 'Calcium, Ca') mealPlanCaAmount.value = nutrient.amount.qty
          else if (nutrient.name == 'Phosphorus, P') mealPlanPAmount.value = nutrient.amount.qty

          planNutrients.value.push(nutrient)
          if(!_.find(pet.value.daily_nutrition_breakdown.nutrients, {id: nutrient.id})) {
            let pet_nutrient = structuredClone(nutrient)
            pet_nutrient.amount.qty = 0
            petNutrients.value.push(pet_nutrient)
          }
        }
        petNutrients.value = _.sortBy(petNutrients.value, 'default_sort_order')
        planNutrients.value = _.sortBy(planNutrients.value, 'default_sort_order')
        for (let i = 0; i < petNutrients.value.length; i++) {
          let petNutrient = petNutrients.value[i]
          if (petNutrient.amount.qty == 0) {
            let deviation = 'N/A'
            targetDeviations.value.push(deviation)
            nutrientRanges.value.push(2)
          } else {
            let planNutrient = planNutrients.value[i]
            let deviation = Math.round((planNutrient.amount.qty / petNutrient.amount.qty) * 100)
            let deviationStr = deviation + '%'

            let dpknNutrient = _.find(pet.value.dietary_per_kg_need.nutrition_breakdown.nutrients, function(n) {
              return n.id == planNutrient.id
            })

            if (dpknNutrient === undefined) nutrientRanges.value.push(2)
            else if (deviation <= dpknNutrient.amount.low_max) nutrientRanges.value.push(1)
            else if (deviation >= dpknNutrient.amount.high_min) nutrientRanges.value.push(3)
            else nutrientRanges.value.push(2)

            targetDeviations.value.push(deviationStr)
          }
        }
        let qtyRecipes = mealPlan.value.recipes.length
        let totalGramsAllRecipes = 0
        for (let i = 0; i < qtyRecipes; i++) {
          let recipe = mealPlan.value.recipes[i]
          totalGramsAllRecipes += parseFloat(recipe.nutrition_breakdown.total_qty)
        }
        for (let i = 0; i < qtyRecipes; i++) {
          let recipe = mealPlan.value.recipes[i]
          let recipeFractionOfTotal = parseFloat(recipe.nutrition_breakdown.total_qty) / totalGramsAllRecipes
          recipesPercentOfMealPlan.value.push(recipeFractionOfTotal*100)
          let gramsPerDayOfRecipe = petGramsPerDay.value * recipeFractionOfTotal
          let recipeMultiplier = gramsPerDayOfRecipe / parseFloat(recipe.nutrition_breakdown.total_qty)
          recipeMultipliers.value.push(recipeMultiplier)

          for (let j = 0; j < petNutrients.value.length; j++) {
            let nutrient = petNutrients.value[j]
            if(!_.find(recipe.nutrition_breakdown.nutrients, {id: nutrient.id})) {
              let recipe_nutrient = structuredClone(nutrient)
              recipe_nutrient.amount.qty = 0
              mealPlan.value.recipes[i].nutrition_breakdown.nutrients[j].push(recipe_nutrient)
            }
          }

          mealPlan.value.recipes[i].nutrition_breakdown.nutrients = _.sortBy(mealPlan.value.recipes[i].nutrition_breakdown.nutrients, 'default_sort_order')
        }
      });
  })

  const recipeHeaderKey = (recipe)  => {
    return 'head-'+recipe.id
  }

  const recipeTotalKey = (recipe)  => {
    return 'total-'+recipe.id
  }

  const recipePercentKey = (recipe)  => {
    return 'percent-'+recipe.id
  }

  const recipeBodyKey = (recipe)  => {
    return 'body-'+recipe.id
  }

  const recipeCaPRatioKey = (recipe)  => {
    return 'ca-p-ratio-'+recipe.id
  }

  const petGramsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value)
  })

  const petCupsPerDayRounded = computed({
      get: () => Math.round(petGramsPerDay.value / 226)
  })

  const petCupsPerDayText = computed({
      get: () => petCupsPerDayRounded.value == 1 ? petCupsPerDayRounded.value + ' cup' : petCupsPerDayRounded.value + ' cups'
  })

  const mealPlanCaPRatio = computed({
    get: () => Math.round((mealPlanCaAmount.value / mealPlanPAmount.value) * 100)
  })

  const formattedMealPlanCaPRatio = computed({
    get: () => (mealPlanCaPRatio.value / 100).toFixed(1) + ':1'
  })

  const recipeCaNutrient = (recipe) => {
    return recipe.nutrition_breakdown.nutrients.find(nutrient => nutrient.name === 'Calcium, Ca')
  }

  const recipePNutrient = (recipe) => {
    return recipe.nutrition_breakdown.nutrients.find(nutrient => nutrient.name === 'Phosphorus, P')
  }

  const recipeCaPRatio = (recipe) => {
    let caAmount = parseFloat(recipeCaNutrient(recipe).amount.qty)
    let pAmount = parseFloat(recipePNutrient(recipe).amount.qty)
    return Math.round((caAmount / pAmount) * 100)
  }

  const formattedRecipeCaPRatio = (recipe) => {
    return (recipeCaPRatio(recipe) / 100).toFixed(1) + ':1'
  }

  const getCaPRatio = async () => {
    try {
      let res = await CaPRatioService.getDefault()
      caPRatio.value = res.data.ca_p_ratio
    } catch (err) {
      console.log('Error getting Ca/P Ratio.')
    }
  }

  const calcRecipeValue = (index, value) => {
    return round(value * recipeMultipliers.value[index])
  }

  const round = (value) => {
    return Math.round(value)
  }

  const rangeLabel = (index) => {
    if (nutrientRanges.value[index] == 1) return 'Low'
    else if (nutrientRanges.value[index] == 2) return 'Optimal'
    else return 'High'
  }

  const rangeClass = (index) => {
    if (nutrientRanges.value[index] == 1) return 'text-yellow-500'
    else if (nutrientRanges.value[index] == 2) return 'text-green-500'
    else return 'text-red-500'
  }

  const caPRatioLabel = () => {
    if (mealPlanCaPRatio.value <= caPRatio.value.low_max) return 'Low'
    else if (mealPlanCaPRatio.value >= caPRatio.value.high_min) return 'High'
    else return 'Optimal'
  }

  const caPRatioClass = () => {
    if (mealPlanCaPRatio.value <= caPRatio.value.low_max) return 'text-yellow-500'
    else if (mealPlanCaPRatio.value >= caPRatio.value.high_min) return 'text-red-500'
    else return 'text-green-500'
  }

  // const getPet = async () => {
  //   try {
  //     let res = await PetService.getPetWithDailyNutrition(route.params.petId)
  //     pet.value = res.data.pet
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }

  // const getRecipe = async () => {
  //   try {
  //     let res = await RecipeService.getRecipe(route.params.recipeId)
  //     recipe.value = res.data.recipe
  //     console.log(res.data)
  //   } catch (err) {
  //     errors.value = err.response.data.errors
  //   }
  // }
</script>
