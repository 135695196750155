<template>
  <div id="RecipeSteps" class="container max-w-4xl mx-auto pt-10 pb-20 px-6" v-if="ready">
    
    <div v-if="step.order == 1" class="flex flex-wrap justify-between mt-4 mb-6 mx-4 md:mx-0">
      <div class="basis-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="flex justify-center">
        <div class="justify-center">
            <h1 class="mb-4 text-[2rem] font-bold text-center text-gray-700">{{recipe.name}}</h1>
            <h2 class="mb-4 text-sm font-bold text-center text-gray-700">Batch for {{ chefpawModel }}</h2>
            <div class="text-center flex justify-center">
                <button
                    class="block px-40 bg-innoorange hover:bg-innoorange-hover text-white rounded-sm py-3 text-sm tracking-wide"
                    type="submit" @click="backToBatchSummaryPage"> View batch summary </button>
            </div>
            <div class="text-center text-innogreen font-[600] text-[4rem]">STEP {{step.order}}:</div>
            <p v-if="hasHeatSensitivity" class="text-red-700 text-center">
              <strong>HEAT SENSITIVE INGREDIENT</strong>
              <br>Make sure you followed the step to prepare for heat sensitive ingredients before adding it.
            </p>
            <p class="text-center text-innogreen text-[1.5rem]"><span class="text-[2rem]">{{ upToFirstXWords(step.basic_instructions, 4) }}</span> {{ remainingWordsAfterFirstX(step.basic_instructions, 4) }}</p>
            <p class="text-center text-innogreen text-[1.5rem]" v-html="$sanitize(step.detailed_instructions)"></p>
            <p class="text-center text-sm m-5">{{ ingredientNotes() }}</p>
        </div>

    </div>
    <div class="flex justify-around pb-[1rem] mt-[1rem]">
        <button v-bind:style="isFirstStep? 'visibility: hidden;' : ''"
            class="block bg-innoorange hover:bg-innoorange-hover text-white p-[30px] rounded-sm tracking-wide" @click="previous">Back</button>
        <button v-bind:style="isLastStep? 'visibility: hidden;' : ''"
            class="block bg-innoorange hover:bg-innoorange-hover text-white p-[30px] tracking-wide" @click="next">Next</button>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import RecipeService from '@/services/recipe.service'

  let ready = ref(false)
  let recipe = ref({name: '...'})
  let index = ref(0)
  // let step = ref({order: 1, basic_instructions: '...', detailed_instructions: '...'})
  let route = useRoute();
  let router = useRouter()

  onMounted(async () => {
    index.value = route.params.step - 1
    await loadRecipeSteps()
    // step.value = steps.value[index.value]
    ready.value = true
  })

  const prevExists = computed({
    get: () => index.value > 0
  })

  const nextExists = computed({
    get: () => steps.value.length > index.value + 1
  })

  const steps = computed({
    get: () => recipe.value.recipe_steps
  })

  const step = computed({
    get: () => recipe.value.recipe_steps[index.value]
  })

  const ingredient = computed({
    get: () => recipe.value == null ? null : step.value.ingredient
  })

  const hasHeatSensitivity = computed({
    get: () => ingredient.value !== null && ingredient.value.heat_sensitivity > 0 ? true : false
  })

  const isFirstStep = computed({
    get: () => index.value === 0
  })

  const isLastStep = computed({
    get: () => index.value === steps.value.length - 1
  })

  const chefpawModel = computed({
    get: () => recipe.value.recipe_multiplier == 5 ? 'ChefPaw Maximus' : '3 Liter ChefPaw'
  })

  const loadRecipeSteps = async () => {
    try {
      let res = await RecipeService.getRecipeWithRecipeSteps(route.params.id)
      recipe.value = res.data.recipe
    } catch (err) {
      console.log(err)
    }
  }

  const backToBatchSummaryPage = () => {
        router.push({ name: 'RecipesBatchSummary', params: { id: route.params.id } })
  }

  const next = () => {
    if (nextExists.value) {
      index.value++
      // step.value = steps.value[index.value]
      router.replace({ name: 'RecipeSteps', params: { id: route.params.id, step: index.value + 1 } })
    }
  }

  const previous = () => {
    if (prevExists.value) {
      index.value--
      // step.value = steps.value[index.value]
      router.replace({ name: 'RecipeSteps', params: { id: route.params.id, step: index.value + 1 } })
    }
  }

  const upToFirstXWords = (str, x) => {
    let retStr = ''
    if (str.length > 0) {
      let words = str.split(' ')
      for (var i = 0; i < x && i < words.length; i++)
      retStr += ' ' + words[i]
    }
    return retStr
  }

  const remainingWordsAfterFirstX = (str, x) => {
    let retStr = ''
    if (str.length > 0) {
      let words = str.split(' ')
      if (words.length > x) {
        for (let i = x; i < words.length; i++) {
          retStr += ' ' + words[i]
        }
      }
    }
    return retStr
  }

  const ingredientNotes = () => {
    let notes = ''
    if (ingredient.value != null && ingredient.value.notes.length > 0) {
      for (let i = 0; i < ingredient.value.notes.length; i++) {
        notes += ' - ' + ingredient.value.notes[i].msg
      }
    }
    return notes
  }

  const goBack = () => {
    router.go(-1)
  }
</script>

