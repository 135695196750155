<template>
  <div id="AddUsdaIngredient" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">
    

    <div class="text-gray-900 text-3xl text-center">Advanced Meal Plans</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="mealPlans.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-[200px]">
              <tbody>
                  <tr v-for="(plan, index) in mealPlans" :key="plan.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        
                        <div class="flex justify-start">
                          {{ plan.name }}
                        </div>

                        <div class="flex justify-start mt-2">
                          <div class="mx-2">
                            <button :id="mealPlanActionsBtnId(plan)" :data-dropdown-toggle="mealPlanActionsDropdownId(plan)" data-dropdown-placement="bottom" class="text-gray-900 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Meal Plan Actions <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div :id="mealPlanActionsDropdownId(plan)" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                              <ul class="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" :aria-labelledby="mealPlanActionsBtnId(plan)">
                                <li>
                                  <a href="javascript:void(0)" @click="goToMealPlanGroceryList(plan)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Grocery List</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" @click="editMealPlan(plan)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit / View</a>
                                </li>
                                <li>
                                  <a href="javascript:void(0)" @click="destroyMealPLan(plan)" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="mx-2">
                            <button v-if="pets.length > 0" :id="mealPlanPetsBtnId(plan)" :data-dropdown-toggle="mealPlanPetsDropdownId(plan)" data-dropdown-placement="bottom" class="text-gray-900 bg-innoorange hover:bg-innoorange-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Calculate Nutrition <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div v-if="pets.length > 0" :id="mealPlanPetsDropdownId(plan)" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
                              <ul class="h-48 py-2 overflow-y-auto text-gray-700 dark:text-gray-200" :aria-labelledby="mealPlanPetsBtnId(plan)">
                                <li v-for="pet in pets" :key="mealPlanPetDropdownKey(plan, pet)">
                                  <router-link :to="{ name: 'PetMealPlanNutrition', params: { petId: pet.id, mealPlanId: plan.id}}" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </router-link>
                                  <!-- <a href="#" class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                    <img class="w-6 h-6 mr-2 rounded-full" :src="petImgUrl(pet)" alt="Pet Image">
                                    {{ pet.name }}
                                  </a> -->
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </th>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToAddMealPlan()">
          Add Meal Plan
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import PetService from '../../services/pet.service.js'
  import MealPlanService from '@/services/mealPlan.service.js'
  import Swal from 'sweetalert2'
  import { initFlowbite } from 'flowbite'

  const router = useRouter()

  let mealPlans = ref([])
  let pets = ref([])
  let errors = ref([])

  onMounted(async () => {
      await Promise.all([getMealPlans(), getPets()])
      initFlowbite()
  })

  const getMealPlans = async () => {
    try {
      let res = await MealPlanService.listMealPlans()
      mealPlans.value = res.data.meal_plans
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const getPets = async () => {
    try {
      let res = await PetService.listPets()
      pets.value = res.data.pets
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const destroyMealPLan = async (plan) => {
    Swal.fire({
      title: 'Are you sure?',
      text: plan.name + "will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        console.log('deleting meal plan id ' + plan.id + '...');
        try {
          await MealPlanService.deleteMealPlan(plan.id)
          getMealPlans()
        } catch (err) {
          console.log(err);
          errors.value = err.response.data.errors
        }
      }
    })
  }

  const editMealPlan = (plan) => {
    router.push({ name: 'EditMealPlan', params: { id: plan.id } })
  }

  const goToAddMealPlan = () => {
    router.push({ name: 'CreateMealPlan' })
  }

  const goToMealPlanGroceryList = (plan) => {
    router.push({ name: 'MealPlanGroceryList', params: { id: plan.id } })
  }

  const petImgUrl = (pet) => {
    if (pet.image == null) return '/img/profile_dog_sample.jpg'
    else return pet.image.url
  }

  const mealPlanPetsBtnId = (mealPlan) => {
    return 'pets-btn-' + mealPlan.id
  }

  const mealPlanPetsDropdownId = (mealPlan) => {
    return 'pets-dropdown-' + mealPlan.id
  }

  const mealPlanPetDropdownKey = (mealPlan, pet) => {
    return mealPlan.id + '-' + pet.id
  }

  const mealPlanActionsBtnId = (mealPlan) => {
    return 'actions-btn-' + mealPlan.id
  }

  const mealPlanActionsDropdownId = (mealPlan) => {
    return 'actions-dropdown-' + mealPlan.id
  }
</script>
