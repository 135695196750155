<template>
  <div id="ListDietaryNeedsPerKg" class="container max-w-4xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goBack()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">Dietary Recommendations (per kg body weight per day)</div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="dietaryNeeds.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Species, Life Phase
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Age & Weight Range
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Activity Levels
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Options
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(dietaryNeed, index) in dietaryNeeds" :key="dietaryNeed.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white">
                        {{ dietaryNeed.name }}
                      </th>
                      <td class="px-6 py-4">
                        <span v-if="dietaryNeed.life_phase">{{ dietaryNeed.life_phase.specie.name }} - {{ dietaryNeed.life_phase.name }}</span>
                        <div v-if="dietaryNeed.species.length > 0">
                          <span v-for="specie in dietaryNeed.species" :key="dneedSpecieDisplayKey(dietaryNeed, specie)"> {{ specie.name }}</span>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        {{ dietaryNeed.age_gte }} - {{ dietaryNeed.age_lt }} {{ dietaryNeed.age_units }}<br>
                        {{ dietaryNeed.weight_gte }} - {{ dietaryNeed.weight_lt }} {{ dietaryNeed.weight_units }}
                      </td>
                      <td class="px-6 py-4">
                        <span v-for="(activityLevel, alIndex) in dietaryNeed.activity_levels" :key="dietaryNeedActivityLevelKey(dietaryNeed, activityLevel)">
                          <span v-if="alIndex > 0">, </span>{{ activityLevel.name }}
                        </span>
                      </td>
                      <td class="px-6 py-4">
                        <div v-if="false">
                          <a href="javascript:void(0)" @click="destroyRecipe(dietaryNeed)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a>
                        </div>
                        <div v-if="dietaryNeed.loading" class="spinner-border animate-spin inline-block w-4 h-4 rounded-full" role="status">
                          <span class="visually-hidden">Destroying...</span>
                        </div>

                        <div>
                          <a v-if="canEdit(dietaryNeed)" href="javascript:void(0)" @click="editDietaryNeed(dietaryNeed)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                          <a v-if="!canEdit(dietaryNeed)" href="javascript:void(0)" @click="showDietaryNeed(dietaryNeed)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">View</a>
                          <span v-if="canEdit(dietaryNeed)">
                            | <a href="javascript:void(0)" @click="deleteDietaryNeed(dietaryNeed)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Delete</a>
                          </span>
                          | <a href="javascript:void(0)" @click="duplicateDietaryNeed(dietaryNeed)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Duplicate</a>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

    <div class="grid place-items-center">
      <div class="p-4 w-11/12 md:w-2/3">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goToAddRecommendation()">
          Add Recommendation
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import DietaryNeedService from '@/services/dietaryNeed.service.js'
  import { useUserStore } from '@/store/user-store'
  import { useToast } from "vue-toastification"
  import Swal from 'sweetalert2'

  const router = useRouter()
  const userStore = useUserStore()
  const toast = useToast()

  let dietaryNeeds = ref([])
  let errors = ref([])

  onMounted(async () => {
      getDietaryNeeds()
  })

  const getDietaryNeeds = async () => {
    try {
      let res = await DietaryNeedService.listDietaryNeedsWithAcitivityLevelsAndLifePhase()
      dietaryNeeds.value = res.data.dietary_per_kg_needs
      console.log('data', dietaryNeeds.value)
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const deleteDietaryNeed = async (dietaryNeed) => {
    Swal.fire({
      title: 'Are you sure?',
      text: dietaryNeed.name + " will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F28705',
      cancelButtonColor: '#618C03',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
        try {
          await DietaryNeedService.deleteDietaryNeed(dietaryNeed.id)
          toast.success(dietaryNeed.name + " deleted.")
          getDietaryNeeds()
        } catch (err) {
          Swal.fire({
            title: 'Error',
            text: err.response.data.message,
            icon: 'error',
            confirmButtonColor: '#F28705'
          })
        }
      }
    })
  }

  const canEdit = (dietaryNeed) => {
    return userStore.isAdmin || dietaryNeed.user_id == userStore.id
  }

  const showDietaryNeed = (dietaryNeed) => {
    router.push({ name: 'ShowDietaryNeedsPerKg', params: { id: dietaryNeed.id } })
  }

  const editDietaryNeed = (dietaryNeed) => {
    if (userStore.isAdmin) {
      router.push({ name: 'EditDietaryNeedsPerKg', params: { id: dietaryNeed.id } })
    } else if (userStore.isProfessional) {
      router.push({ name: 'EditDietaryNeedsPerKgPro', params: { id: dietaryNeed.id } })
    }
  }

  const duplicateDietaryNeed = async (dietaryNeed) => {
    Swal.fire({
      title: 'New Dietary Recommendation Name',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#F28705',
      showLoaderOnConfirm: true,
      preConfirm: (name) => {
        return DietaryNeedService.duplicateDietaryNeed(dietaryNeed.id, name)
          .then(response => {
            if (!response.status == 201) {
              throw new Error(response.message)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Error: ${error.response.data.message}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        editDietaryNeed(result.value.dietary_per_kg_need)
      }
    })
  }

  const dneedSpecieDisplayKey = (dietaryNeed, specie) => {
    return dietaryNeed.id + '-' + specie.id
  }

  const goToAddRecommendation = () => {
    router.push({ name: 'AddDietaryNeedsPerKg' })
  }

  const dietaryNeedActivityLevelKey = (dietaryNeed, activityLevel) => {
    return dietaryNeed.id + '-' + activityLevel.id
  }

  const goBack = () => {
    router.go(-1)
  }
</script>
