<template>
  <div id="AddUsdaIngredient" class="container max-w-6xl mx-auto pt-20 pb-20 px-6">

    <div class="flex flex-wrap mt-4 mb-6 mx-4 md:mx-4">
      <div class="w-1/4">
        <button class="
          block
          w-full
          bg-innoorange
          hover:bg-innoorange-hover
          text-white
          rounded-sm
          py-3
          text-sm
          tracking-wide  
        "
          type="submit"
          @click="goAdminHome()">
          Back
        </button>
      </div>
    </div>

    <div class="text-gray-900 text-3xl text-center">
      AAFCO Nutrient Bounds
      <br><span class="text-base">Lower and upper bounds for AAFCO Dry Matter Basis (DMB) &amp; Per 1000 kcal (DM) for adult dogs and puppies.</span>
    </div>
    <div class="bg-innogreen w-full h-1 my-2"></div>

    <div v-if="nutrients.length > 0">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  
              </thead>
              <tbody>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="4">
                        Dry Matter Basis (DMB) %
                    </th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="4">
                        Per 1000 kcal (DM) grams
                    </th>
                </tr>
                <tr>
                    <th scope="col"></th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="2">
                        Puppy
                    </th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="2">
                        Adult Dog
                    </th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="2">
                        Puppy
                    </th>
                    <th scope="col" class="px-3 py-1 text-center" colspan="2">
                        Adult Dog
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" class="px-3 py-1">
                      Nutrient Name
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too Low Max
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too High Min
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too Low Max
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too High Min
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too Low Max
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too High Min
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too Low Max
                    </th>
                    <th scope="col" class="px-3 py-1">
                        Too High Min
                    </th>
                  </tr>
                  <tr v-for="(nutrient, index) in nutrients" :key="nutrient.id"
                    :class="index % 2 == 0 ? 'border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700' : 'bg-white border-b dark:bg-gray-900 dark:border-gray-700'">
                      <th scope="row" class="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {{ nutrient.name }}
                      </th>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dmb_low_max_puppy" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dmb_high_min_puppy" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dmb_low_max_adult_dog" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dmb_high_min_adult_dog" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dm_low_max_puppy" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dm_high_min_puppy" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dm_low_max_adult_dog" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                      <td class="px-3 py-4">
                        <input type="text" class="w-20" v-model="nutrient.dm_high_min_adult_dog" @keyup.enter="saveNutrient(nutrient)">
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>

  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import nutrientService from '@/services/nutrient.service'
  import { useToast } from "vue-toastification"

  const router = useRouter()
  
  let nutrients = ref([])
  let errors = ref([])
  const toast = useToast()

  onMounted(async () => {
    await Promise.all([getNutrients()])
  })

  const getNutrients = async () => {
    try {
      let res = await nutrientService.getNutrients()
      nutrients.value = res.data.nutrients
    } catch (err) {
      errors.value = err.response.data.errors
    }
  }

  const saveNutrient = async (nutrient) => {
    let data = new FormData();
    data.append('nutrient', JSON.stringify(nutrient))

    try {
      await nutrientService.updateNutrient(nutrient.id, data)
      toast.success(nutrient.name + " saved.")
    } catch (err) {
      if (err.response.data.errors && Array.isArray(err.response.data.errors)) errors.value = err.response.data.errors
      toast.error(err.response.data.message)
    }
  }

  const goAdminHome = () => {
    router.replace({ name: 'AdminHome' })
  }
</script>
